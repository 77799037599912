.main-header.main-page .header-nav {
    display: flex;
    position: absolute;
    transform: translateX(-105%);
    /* background-color: white; */
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.25);
    z-index: 20;
    /* width: 20rem; */
    height: 100vh;
    align-items: center;
    left: 0;
    top: 0;
    transition: transform 250ms;
}



.main-header.main-page .header-nav.active {
    transform: translateX(0%);
    flex-direction: column;
}

.main-header.main-page .header-nav>ul.navigation-list>li {
    width: 100%;
    border-bottom: 1px solid #7b7b7b;
}

.main-header.main-page .header-nav>ul.navigation-list>li>a:not(ul.drop_link a) {
    display: block;
    width: 100%;
    height: auto;
    /* padding: 0 0 0 3rem !important; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: black;
    font-size: .95rem;
}

.main-header.main-page img.logo {
    width: 100%;
    max-width: 18rem;
}

.main-header.main-page .header-nav>ul.navigation-list {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    height: 100%;
    gap: 0;
    flex-direction: column;
    width: 100%;
    margin: 0;
    justify-content: flex-start;
    background: #1c1c1c94;
}

.main-header.main-page .main_page_header-burger {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70%;
    justify-content: flex-end;
    z-index: 9999;
}

.main-header.main-page .header-burger {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70%;
    justify-content: flex-end;
    z-index: 99;
}

.main-header.main-page .menuToggle {
    display: flex;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    flex-direction: column;
    justify-content: center;
}

.main-header.main-page .menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
}

.main-header.main-page .menuToggle a:hover {
    color: tomato;
}

.main-header.main-page .menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    left: -4px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.main-header.main-page .menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.main-header.main-page .menuToggle span:first-child {
    transform-origin: 0% 0%;
}

.main-header.main-page .menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

.main-header.main-page .menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -18px);
    background: white;
}

.main-header.main-page .menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.main-header.main-page .menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-1px, 18px);
}

.main-header.main-page .header-burger.inner-check {
    position: absolute;
    top: 0.5rem;
    display: block;
    right: 1.5rem;
    width: auto;
}

.main-header.main-page .out-check {
    opacity: 1;
    transition: opacity 250ms;
    z-index: 16;

}

.main-header.main-page .inner-check {
    opacity: 0;
    transition: opacity 250ms;
}

.main-header.main-page .header-flex.active .overlay-header {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 15;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.25);
    cursor: pointer;
}


.main-header.main-page {
    position: absolute;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
    top: -100%;

    transition: top 1000ms ease-out;
}

.main-header.main-page .drop_link_main {
    overflow: hidden;
}

.main-header.main-page[op="op"] {
    top: 3rem;
}

.main-header.main-page .header-nav.active {
    top: -5rem;
    width: 100%;
    left: 0;
    max-width: 100%;
    background-color: #0000005e;
}

.main-header.main-page .header-nav>ul.navigation-list>li>a:not(ul.drop_link a) {
    color: white;
    opacity: 1;
    text-align: center;
}

.main-header.main-page .header-nav>ul.navigation-list {
    justify-content: center;
}

.main-header.main-page .header-nav>ul.navigation-list>li {
    text-align: center !important;
    width: 100%;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: auto !important;
}

.main-header.main-page .header-nav>ul.navigation-list>li>a {
    align-items: center !important;
    height: auto !important;
    padding: 1rem 0;
    font-size: 3.5rem !important;
}

.drop_link_main[open="open"] {
    display: flex;
    flex-direction: column;
}

.drop_link_main[open="open"] .drop_link {
    position: relative;
    z-index: 999;
    left: 0;
    max-height: max-content !important;
    top: 0;
    margin-bottom: 2rem;
}

.main-header.main-page .drop_link a {
    font-size: 1.25rem !important;
    letter-spacing: normal !important;
    padding: 1rem 6rem;
}

/* .main-header.main-page .header-nav>ul li.drop_link_main:hover .drop_link {
    max-height: max-content !important;
} */



.main-header.main-page .drop_link a {
    padding: 0;
    color: white;
    opacity: 1;
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    font-size: 1.35rem !important;
}

.main-header.main-page .drop_link a:hover {
    background-color: rgb(49, 49, 49);
}

.main-header.main-page .drop_link_main .drop_link {
    position: relative;
    overflow: hidden;
    max-height: 0;
    width: 0;
    left: inherit;
    right: 0%;
    transition: max-height 250ms,
        width 250ms, right 250ms;
}

.main-header.main-page .header-nav>ul li.drop_link_main.active .drop_link {
    max-height: 30rem !important;
    width: 35rem !important;
}

.main-header.main-page .drop_link_main .drop_link {
    position: relative !important;
    top: 0 !important;
    border-radius: 0 !important;
}

.main-header.main-page .drop_link_main.active .drop_link {
    top: 0;
    border-radius: 0;
    right: 0;
}

@media (max-width: 992px) {
    .main-header.main-page .header-nav>ul.navigation-list>li>a {
        font-size: 1.6rem !important;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Montserrat:wght@100..900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Monument Extended Regular';
  src: url('https://storage.acerapps.io/app-1176/falcon/MonumentExtended-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Extended UltraBold';
  src: url('https://storage.acerapps.io/app-1176/falcon/MonumentExtended-Ultrabold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.top_header {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.pageAnimation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(255, 0, 0);
  background: linear-gradient(135deg, rgb(166 45 45) 0%, rgb(0 0 0) 100%);
  z-index: 99999999999999999999;
  overflow: hidden;
}

.page-top.index {
  height: 100vh !important;
  overflow: hidden !important;
}

header.l-header.js-header.b-fonts__medium {
  position: absolute;
  user-select: none;
}

._Page_Content_Area {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* div#stage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 997 !important;
} */

*:not(img, i, svg, #page *) {
  font-family: "Poppins", sans-serif;
}

*:not(h1, h2, h3, h4, h5, h6, #page *, .video *) {
  /* font-size: 1rem; */
}

.page_content {
  color: #bababa;
}

:root {
  --black: #252525;
}

main {
  margin-top: 2rem;
  /* display: flex; */
  width: 100%;
}

.page_banner_main {
  position: relative;
}

.page_banner_main:after {
  content: "";
  background-color: #404040;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

.page_banner h1 {
  font-size: 5rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-family: 'Monument Extended UltraBold', sans-serif !important;
}

@media (max-width: 768px) {
  .page_banner h1 {
    font-size: 2.25rem;
    letter-spacing: 0.35rem;
  }
}

@media (max-width: 480px) {
  .page_banner h1 {
    font-size: 1.75rem;
    text-align: center;
    letter-spacing: 0.25rem;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: auto !important;
}

body {
  background-color: var(--black);
  color: white;
}

/* .page_left_area {
  padding: 0 1rem;
  position: sticky;
  bottom: 1rem;
  left: 0;
  margin-right: auto;
}

.page_right_area {
  padding: 0 1rem;
  position: sticky;
  bottom: 1rem;
  right: 0;
  margin-left: auto;
} */

.page_right_area img {
  max-width: 13rem;
  display: flex;
  margin: auto;
  width: 100%;
  position: sticky;
  top: 3rem;
}

.page_left_area ul {
  list-style: none;
  padding: 0 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 3rem;
}

.page_left_area p {
  margin: 0;
}

.page_left_area a,
.page_left_area span {
  color: white;
  opacity: .75;
  text-decoration: none;
  font-size: 1.5vmin;
}

@media (min-width: 1740px) {

  .container-fluid {
    padding-right: calc(var(--bs-gutter-x)* 1.1);
    padding-left: calc(var(--bs-gutter-x)* 1.1);
  }

  .container {
    /* max-width: 800px; */
    padding: 0 2rem;
  }

  .page_left_area,
  .page_right_area {
    width: calc((100vw - 78rem) / 2);
  }
}

@media (max-width: 1740px) {

  .page_left_area,
  .page_right_area {
    display: none;
  }
}


/* Header */

.main-header {
  width: 100%;
  height: 5rem;
  display: flex;
  margin-top: 2rem;
}

.header-flex {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.header-logo {
  width: 100%;
  max-width: 30%;
  height: 100%;
}

.header-nav {
  width: 100%;
  max-width: 70%;
  height: 100%;
}

.header-logo img {
  width: 100%;
  height: 100%;
  max-width: 15rem;
  object-fit: scale-down;
  object-position: left;
  user-select: none;
}

.header-nav>ul.navigation-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.header-nav>ul.navigation-list>li>a {
  text-decoration: none;
  font-size: .85rem;
  font-weight: 400;
  color: white;
  opacity: .6;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 250ms;
}

.header-nav>ul.navigation-list>li>a:hover,
.header-nav>ul.navigation-list>li.active>a {
  opacity: 1;
}

.header-burger {
  display: none;
}


.drop_link_main>a {
  cursor: pointer;
}


@media only screen and (max-width: 1200px) {
  .header-nav>ul.navigation-list {
    gap: 1rem;
  }
}

@media only screen and (min-width: 992px) {

  .navigation-list>li {
    height: 2.5rem;
  }

  .header-nav>ul.navigation-list li.drop_link_main {
    position: relative;
  }

  .drop_link {
    position: absolute;
    padding: 0;
    top: 2.5rem;
    z-index: 99;
    right: -3rem;
    width: max-content;
    list-style: none;
    background-color: white;
    border-radius: .6rem;
    overflow: hidden;
    box-shadow: 0 0 0 rgb(0, 0, 0, 0);
    max-height: 0;
    transition: max-height 250ms, box-shadow 250ms;
  }

  .header-nav>ul li.drop_link_main:hover .drop_link:not(.main-header.main-page .header-nav>ul li.drop_link_main:hover .drop_link) {
    max-height: 25rem;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }

  .drop_link li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 3rem;
    background-color: white;
    font-size: .75rem;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    transition: background-color 250ms, color 250ms;
  }

  .drop_link li a:hover,
  .drop_link li.active a {
    background-color: var(--black);
    color: white;
  }
}

@media only screen and (max-width: 992px) {
  .header-nav {
    display: flex;
    position: absolute;
    transform: translateX(-105%);
    background-color: white;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.25);
    z-index: 20;
    width: 20rem;
    height: 100vh;
    align-items: center;
    left: 0;
    top: 0;
    transition: transform 250ms;
  }



  .header-nav.active {
    transform: translateX(0%);
    flex-direction: column;
  }

  .header-nav>ul.navigation-list>li {
    width: 100%;
    border-bottom: 1px solid #7b7b7b;
  }

  .header-nav>ul.navigation-list>li>a:not(ul.drop_link a) {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 0 0 1rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: black;
    font-size: .95rem;
  }



  .header-nav>ul.navigation-list {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    height: 100%;
    gap: 0;
    flex-direction: column;
    width: 100%;
    margin: 0;
    justify-content: flex-start;
  }

  .header-burger {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70%;
    justify-content: flex-end;
    z-index: 99;
  }

  .menuToggle {
    display: flex;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    flex-direction: column;
    justify-content: center;
  }

  .menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  .menuToggle a:hover {
    color: tomato;
  }

  .menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    left: -4px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  .menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  .menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -18px);
    background: white;
  }

  .menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  .menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-1px, 18px);
  }

  .header-burger.inner-check {
    position: absolute;
    top: 0.5rem;
    display: block;
    right: 1.5rem;
    width: auto;
  }

  .out-check {
    opacity: 1;
    transition: opacity 250ms;
    z-index: 16;

  }

  .inner-check {
    opacity: 0;
    transition: opacity 250ms;
  }

  .drop_link {
    list-style: none;
    padding: 0 0 0 0;
    max-height: 0px;
    height: 100%;
    transition: max-height 250ms;
    overflow-y: hidden;
  }

  .drop_link a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 0 0 0 1.75rem;
    text-decoration: none;
    color: black;
    font-size: .85rem;
    opacity: .7;
    transition: color 250ms,
      opacity 250ms,
      background-color 250ms;
  }

  .drop_link li {
    border-top: 1px solid #7b7b7b;
  }

  .header-nav>ul.navigation-list li.drop_link_main.active .drop_link {
    max-height: max-content;
  }

  .drop_link li:hover a,
  .drop_link li.active a {
    color: white;
    opacity: 1;
    background-color: var(--black);
  }

  .header-flex.active .overlay-header {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 15;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

ul.footer_links>li {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  text-align: center;
  text-align: center;
  justify-content: center;
}

/* Hakkımızda */

.page_content {
  margin-top: 2rem;
}

.page_content img {
  width: 100%;
  max-height: 35rem;
  object-fit: contain;
}

.pageTransitionImage {
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  margin: auto;
  display: flex;
  height: 100vh;
  object-fit: contain;
}

footer {
  background-color: #131313;
  /* min-height: 50rem; */
  padding-bottom: 4rem;
  margin-top: 4rem;
}

hr {
  margin: 0;
  padding: 0;
}

.footer_logo {
  width: 100%;
  height: auto;
  max-width: 20rem;
  padding: 1rem;
  margin: auto;
  display: flex;
  padding: 2rem 0;
}

.footer_links,
.footer_contact {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: 8rem;
  padding: 2rem 0;
}

.footer_links a,
.footer_contact a {
  text-decoration: none;
  color: white;
  opacity: .7;

  transition: opacity 250ms;
}

.footer_links a:hover,
.footer_contact a:hover {
  opacity: 1;
}

.footer_contact p {
  font-size: 1.5rem !important;
}

.footer_contact {
  text-align: center;
  gap: 4rem;
}

.footer_contact>li {
  width: calc(100% / 3);
}

@media (max-width: 992px) {
  .footer_contact {
    flex-wrap: wrap;
    gap: 3rem 0;
  }

  .footer_contact>li {
    width: 50%;
  }

  .footer_contact>li:last-child {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footer_links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 576px) {
  .footer_contact>li {
    width: 100%;
  }
}

.copyright {
  background-color: #131313;
  position: relative;
}

.copyright p {
  text-align: center;
  margin: 0;
  padding: 1rem 0;
}


.social_area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social_area>a {
  border: 1px solid white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 250ms;
}

.social_area>a:hover {
  background-color: #414141;
}

.copyright .social_area {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.copyright .social_area a {
  color: white;
  text-decoration: none;
  opacity: .7;

  transition: opacity 250ms;
}

.copyright .social_area a:hover {
  opacity: 1;
}

@media (max-width: 992px) {
  .copyright .social_area {
    position: relative;
    padding-bottom: 1rem;
  }

  .copyright>div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.full_area {
  width: 100%;
}


.product_attrs {
  margin: 4rem 0;
}

.iframe_area {
  margin-bottom: 4rem;
}

.product_attrs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* flex-wrap: wrap; */
  gap: .15rem .15rem;
}

.product_attrs li {
  /* width: calc((100% / 3) - 0.067rem); */
  width: 100%;
  border: 1px solid rgb(127, 127, 127);
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}

@media (max-width: 992px) {
  .product_attrs ul {
    flex-wrap: wrap;
  }

  .product_attrs li {
    width: calc(50% - 0.074rem);
  }
}

@media (max-width: 480px) {
  .product_attrs li {
    width: 100%;
  }
}

.product_attrs li>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.head_attr {
  height: 4rem;
  padding: .5rem;
  border-bottom: 1px solid rgb(127, 127, 127);
  background-color: rgb(187, 13, 13);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.head_attr p {
  margin: 0;
  font-weight: bold;
}


.form-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1rem;
}

.form-flex>div {
  width: calc((100% / 3) - .67rem);
}

.form-flex>.f-w {
  width: 100%
}

form .form-flex input,
form .form-flex textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(195, 195, 195);
  padding: 1rem;
  outline: none;
  color: white;

  transition: background-color 250ms,
    border-color 250ms;
}

form .form-flex input:focus,
form .form-flex textarea:focus {
  background-color: rgb(22, 22, 22);
  border-color: white;
}

form .form-flex textarea {
  min-height: 10rem;
  max-height: 15rem;
}

.form_title {
  color: white;
  text-align: center;
  padding: 2rem 0;
  font-size: 3rem;
}

form button,
button.btn_style {
  background-color: #E20715;
  color: white;
  border: .313 solid;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: auto;
  box-shadow: inset 0 0 0 rgb(0, 0, 0);

  transition: border-color 250ms, box-shadow 250ms;
}

form button:hover,
button.btn_style:hover {
  border-color: #8c0a13;
  box-shadow: inset 0 0 .313rem rgb(0, 0, 0);
}

span.validation_error_span {
  font-size: .9rem !important;
  margin-top: .25rem;
  display: block;
  color: #f30e1d;
}

.swal-text,
.swal-footer {
  text-align: center;
}

@media (max-width: 768px) {
  .form-flex>div {
    width: 100%;
  }
}








/* Panel */

.vertical-menu {
  top: 0;
  box-shadow: none;
}

/* .page-content {
  padding: 70px calc(1.5rem / 2) 60px calc(1.5rem / 2);
} */

.table_title {
  font-size: .75rem !important;
  font-weight: 400;
  margin: 0;
}

.table_content {
  display: -webkit-box;
  display: -ms-flexbox;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  max-width: 30rem;
}

.show_content {
  color: rgb(11, 115, 175);
  cursor: pointer;
}

.contentModal {
  color: rgb(73, 73, 73);
}

.simplebar-content-wrapper {
  background: rgb(0 0 0);
}

#sidebar-menu ul li a,
#sidebar-menu ul li a i {
  text-decoration: none;
  color: #aaaaaa;
}

#sidebar-menu ul li a:hover {
  color: white;
}

#sidebar-menu ul li a:hover i {
  color: white;
}

.table_action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table_action button {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.table_action button i {
  font-size: 0.80rem !important;
  width: 1rem;
  height: 1rem;
  padding-top: .2rem;
}


.modal-content {
  color: rgb(73, 73, 73);
}


.form_flex {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
}

.modal-content label {
  font-size: .9rem !important;
  font-weight: 600;
}

.modal-content form input {
  width: 100%;
  height: 2.3rem;
  border: 1px solid rgb(179, 179, 179);
  padding: 1rem;
  outline: none;
  font-size: .85rem !important;

  transition: border-color 250ms;
}

.modal-content form input:focus {
  border-color: black;
}

.three_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.three_flex>div {
  width: calc((100% / 3) - .67rem);
}

.modal-dialog {
  max-width: 50rem !important;
  margin: 1rem auto;
}

.ck.ck-editor__top.ck-reset_all * {
  font-size: 0.7rem !important;
  outline: none !important;
  box-shadow: none;
}

.ck.ck-content.ck-editor__editable {
  min-height: 10rem;
  color: black;
}

@media (max-width: 830px) {
  .modal-dialog {
    max-width: 100% !important;
    margin: 1rem;
  }
}

@media (max-width: 576px) {
  .three_flex>div {
    width: 100%
  }
}

.type_file {
  padding: 0 1rem;
  width: 100%;
  height: 2.5rem;
  outline: none;
  border: 1px solid rgb(179, 179, 179);
  background: white;
  color: black;
}

.type_file {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 5rem;
  position: relative;
}

.type_file p {
  margin: 0;
  font-size: 0.75rem;
}

.type_file_prev {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.type_file_prev img {
  width: 5rem;
  padding: 0.5rem;
}

.type_file input[type="file"] {
  padding: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  left: 0;
  height: 100%;
}

/* Image Crop */

.CropImageArea {
  width: 100%;
  height: 100%;
}

.CropModal {
  position: relative;
  min-height: 580px;
}

._Modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: opacity 250ms, visibility 250ms;
}

._Modal.open {
  opacity: 1;
  visibility: visible;
}

._Modal_Overlay {
  background-color: black;
  opacity: .16;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}



._Modal_Content {
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  margin: 2rem auto;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: max-content;
  background: white;
  border: var(--pxOne);

  z-index: 999;
}

._Modal_Head:not(._Modal_Head.info) {
  padding: 1rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  border-bottom: var(--pxOne);
}

._Modal_Head.info {
  border-bottom: var(--pxOne);
}

._Modal_Head.info>div {
  padding: 1rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

._Modal_Head h3 {
  margin: 0;
}

._Modal_Head i {
  font-size: 1.5rem;
  cursor: pointer;
}

._Modal_Head i:hover {
  opacity: .75;
}

._Modal_Footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-top: var(--pxOne);
}

._Modal_Body {
  padding: 1rem;
}

.p-roll__list__item a {
  letter-spacing: .5rem !important;
  line-height: 1.55 !important;
}

label small {
  font-size: .75rem !important;
  font-weight: 300;

}


.swal2-actions {
  /* display: flex; */
  align-items: center;
  gap: 1rem;
}


.about_area {
  width: 100%;
  height: calc(100vh - 12.9rem);
  margin-bottom: 1rem;
  border: 1px solid rgb(143, 143, 143);
  transition: all 250ms;
  overflow-y: auto;
  padding: 1rem;
}

.about_area .img {
  /* width: 100%; */
  /* height: 16rem; */
  border: 1px solid rgb(143, 143, 143);
  transition: all 250ms;
  position: relative;
  overflow: hidden;
}

/* 
.about_area .img1 {
  height: 25rem;
} */

.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.about_area .ck.ck-editor__main>.ck-editor__editable {
  background: transparent;
  color: #bababa;
  border-color: rgb(143, 143, 143) !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  display: none !important;
}


.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: #bababa;
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding: 5rem 0;
}

/* .inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
} */

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}

.current .inputfile+label {
  padding: .5rem;

}

.current_file {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(53, 53, 53, 0.74);

  display: flex;
}

.current_file label {
  font-size: .9rem;
  color: white;
  transition: all 250ms;
}

.remove_image {
  padding: .5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  color: #bababa;
  background-color: transparent;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.current_file label:hover {
  background-color: black;
  box-shadow: 0 0 10px rgb(65, 65, 65, .3);
}


.about_area .loader {
  text-align: center;
}

.dataLoad {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.dataLoad p {
  font-size: 1.5rem;
  font-weight: bold;
}

.product_slide_item {
  position: relative;
  overflow: hidden;
  transition: all 250ms;
}

.product_slide_item>a {
  transition: all 250ms;
  color: white;
  opacity: .7;
}

.product_slide_item:hover>a {
  opacity: 1;
}

.product_slide_item img {
  transition: transform 250ms;
}

.product_slide_item:hover img {
  transform: scale(1.1);
}

.product_slide_item>a:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  background: rgb(0, 0, 0);
  background: linear-gradient(14deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 54%);
}

.product_slide_item p {
  position: absolute;
  bottom: 0;
  left: 1rem;
  z-index: 3;
  font-size: 1.15rem;
  font-family: 'Monument Extended Regular', sans-serif !important;
}

.product_slide_item>a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

/* .settings_area input,
.settings_area textarea {
  width: 100%;
  height: 3rem;
  font-size: .9rem;
  padding: 1rem;
}

.settings_area textarea {
  min-height: 10rem;
  max-height: 15rem;
} */

.settings_area input,
.settings_area textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  border: none;
  border: 1px solid rgb(148, 148, 148);
  padding: 1rem;
  outline: none;
  color: white;

  transition: background-color 250ms,
    border-color 250ms;
}

.settings_area input:focus,
.settings_area textarea:focus {
  background-color: rgb(22, 22, 22);
  border-color: white;
}

.settings_area textarea,
.location_input {
  min-height: 10rem;
}

.settings_area label {
  font-size: .9rem;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.form_flex label {
  display: flex;
  align-items: center;
  gap: .5rem;
}


.location_input {
  margin-bottom: 1.5rem;
}

.settings_flex {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.settings_input_flex {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.settings_input_flex>div {
  width: 100%;
}

@media (max-width: 768px) {
  .settings_input_flex {
    flex-wrap: wrap;
  }
}


.tooltip_icon {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(0, 147, 233);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  cursor: pointer;
}

.core-styles-module_tooltip__3vRRp {
  z-index: 1111;
}

.product_attrs li>div:last-child {
  padding: .5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.main-content .page-content {
  padding-top: 0;
}

.top_header .header-item {
  color: white;
}





/* form.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}


form.form .inputBox {
  display: flex;
  flex-direction: column;
}

 */


.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@media (max-width: 600px) {
  .background {
    display: none;
  }
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:nth-child(1) {
  background: linear-gradient(to right, #E20715,
      #664f1e);
  left: -80px;
  top: -80px;
}

.shape:nth-child(2) {
  background: linear-gradient(to left,
      #E20715,
      #664f1e);
  right: -80px;
  bottom: -80px;
}

.shape:nth-child(3) {
  background: linear-gradient(to right, #E20715,
      #664f1e);
  left: -80px;
  bottom: -80px;
}

.shape:nth-child(4) {
  background: linear-gradient(to left, #E20715,
      #664f1e);
  right: -80px;
  top: -80px;
}

form.form {
  height: auto;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

form.form * {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

form.form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

form.form label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}

form.form input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

form.form ::placeholder {
  color: #e5e5e5;
}

/* form.form button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
} */

form.form button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
}

form.form h3 span {
  font-weight: bold;
  color: #E20715;
  text-shadow: 0 0 5px rgb(0, 0, 0, .25);
  font-size: 2rem;
  font-family: "Monument Extended Regular" !important;
}



.other_title {
  font-size: 3rem;
  font-family: 'Monument Extended UltraBold', sans-serif !important;
  color: white;
}

@media (max-width: 768px) {
  .other_title {
    font-size: 2rem;
  }
}





.first_load {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  background-color: rgb(53, 53, 53);
}

.first_load img {
  width: 100%;
  padding: 1.5rem;
  max-width: 40rem;
}


.penging {
  position: absolute;
  background: #00000096;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  overflow: hidden;
}


.tables {
  width: 100%;
  height: auto;
  border: 2px solid white;
  overflow-y: auto;
  /* padding: 1rem; */
}

.tables .top {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tables_area_flex {
  display: flex;
  gap: .5rem;
}

.tables_area_flex>div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tables_area_flex>div input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  outline: none;
  border: 1px solid rgb(164, 164, 164);
}

form.tables select,
select.choise {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  outline: none;
  border: 1px solid rgb(164, 164, 164);
}


.tables_head {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: .5rem 0 1rem 0;
}

.tables_head>div {
  width: 50%;
  display: flex;
  gap: 1rem;
}

.tables_head>div button {
  width: 100%;
}

.pro_table_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 70%;
  margin: 4rem auto;
}

.pro_table_flex>div {
  width: 100%;
}

.pro_table_flex>div:first-child {
  width: 30%;
}

.pro_table_flex hr {
  color: rgb(207, 22, 22);
  opacity: 1;
}

.pro_table_flex ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.pro_table_flex p {
  margin: .25rem 0;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 1.15rem;
}

.pro_table_flex ul li {
  width: calc(100% / 11);
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.05rem;
}


.pro_table_flex .first_list li {
  position: relative;
  padding-bottom: 1.5rem;
}

.pro_table_flex .first_list li:after {
  position: absolute;
  content: "";
  width: .7rem;
  height: .7rem;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(2, 0, 36, 1) 100%);
  border-radius: 50%;
  bottom: .5rem;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 992px) {
  .pro_table_flex {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .pro_table_flex {
    max-width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .pro_table_flex ul li {
    font-size: .75rem;
  }
}

@media (max-width: 400px) {
  .pro_table_flex ul li {
    font-size: .6rem;
  }
}


.table_image {
  max-width: 10rem;
  height: 10rem;
  padding: 0.6rem;
}

.dkyUwu {
  padding: 3rem 0;
}

@media (max-width: 660px) {
  .photos_table div[data-column-id="3"] {
    display: none;
  }

  /* 
  .photos_table div[data-column-id="4"] {
    display: flex;
    justify-content: flex-end;
  } */

}




.not_found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.come_back {
  margin: .5rem 0 0 0 !important;
  padding: 0.7rem 0.7rem !important;
  font-size: 0.9rem;
}

.full_area iframe:not(.contact_page iframe) {
  width: 100%;
  max-width: 800px;
  height: 500px;
  margin: auto;
  display: block;
}

.page_left_area {
  overflow-wrap: break-word
}
/* html, body{
    background-color: white;
} */

div[data-tag="allowRowEvents"] {
    font-size: 0.8rem !important;
}

.vertical-menu {
    display: block !important;
}

.vertical-menu {
    transition: left 250ms;
    left: 0%;
}


@media (max-width: 992px) {
    .navbar-brand-box {
        width: 240px;
    }

    .vertical-menu {
        display: block;
        left: -90%;
        transition: left 250ms;
    }

    .vertical-menu.open {
        left: 0%;
    }


}

/* 
:root{
    --bs-modal-width: 50rem;
}

.modal-dialog {
    max-width: 50rem !important;
}

@media (min-width: 576px) {
    
} */

@media (max-width: 2150px) {
    .product_table div[data-column-id="9"] {
        display: none;
    }
}

@media (max-width: 1920px) {

    .product_table div[data-column-id="8"] {
        display: none;
    }
}

@media (max-width: 1455px) {

    .product_table div[data-column-id="7"] {
        display: none;
    }
}

@media (max-width: 1360px) {

    .product_table div[data-column-id="6"] {
        display: none;
    }
}

@media (max-width: 1200px) {

    .product_table div[data-column-id="5"] {
        display: none;
    }
}

@media (max-width: 768px) {

    .product_table div[data-column-id="4"] {
        display: none;
    }
}